// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {
  --ion-border-radius: 15px;
  /** primary **/
  --ion-color-primary: #115950;
	--ion-color-primary-rgb: 17,89,80;
	--ion-color-primary-contrast: #ffffff;
	--ion-color-primary-contrast-rgb: 255,255,255;
	--ion-color-primary-shade: #0f4e46;
	--ion-color-primary-tint: #296a62;

	--ion-color-secondary: #22a596;
	--ion-color-secondary-rgb: 34,165,150;
	--ion-color-secondary-contrast: #ffffff;
	--ion-color-secondary-contrast-rgb: 255,255,255;
	--ion-color-secondary-shade: #1e9184;
	--ion-color-secondary-tint: #38aea1;

	--ion-color-tertiary: #1f86b2;
	--ion-color-tertiary-rgb: 31,134,178;
	--ion-color-tertiary-contrast: #ffffff;
	--ion-color-tertiary-contrast-rgb: 255,255,255;
	--ion-color-tertiary-shade: #1b769d;
	--ion-color-tertiary-tint: #3592ba;

	--ion-color-success: #27eb00;
	--ion-color-success-rgb: 39,235,0;
	--ion-color-success-contrast: #000000;
	--ion-color-success-contrast-rgb: 0,0,0;
	--ion-color-success-shade: #22cf00;
	--ion-color-success-tint: #3ded1a;

	--ion-color-warning: #dee109;
	--ion-color-warning-rgb: 222,225,9;
	--ion-color-warning-contrast: #000000;
	--ion-color-warning-contrast-rgb: 0,0,0;
	--ion-color-warning-shade: #c3c608;
	--ion-color-warning-tint: #e1e422;

	--ion-color-danger: #eb445a;
	--ion-color-danger-rgb: 235,68,90;
	--ion-color-danger-contrast: #ffffff;
	--ion-color-danger-contrast-rgb: 255,255,255;
	--ion-color-danger-shade: #cf3c4f;
	--ion-color-danger-tint: #ed576b;

	--ion-color-medium: #92949c;
	--ion-color-medium-rgb: 146,148,156;
	--ion-color-medium-contrast: #000000;
	--ion-color-medium-contrast-rgb: 0,0,0;
	--ion-color-medium-shade: #808289;
	--ion-color-medium-tint: #9d9fa6;

	--ion-color-light: #e9f1f2;
	--ion-color-light-rgb: 233,241,242;
	--ion-color-light-contrast: #000000;
	--ion-color-light-contrast-rgb: 0,0,0;
	--ion-color-light-shade: #cdd4d5;
	--ion-color-light-tint: #ebf2f3;

  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;
}

body {
  .border-bottom {
    // border-bottom: 0.1px solid var(--ion-color-primary);
  }
}

@media (prefers-color-scheme: dark) {
  /*
   * Dark Colors
   * -------------------------------------------
   */

  body {
    .border-bottom {
      border-bottom: 0.1px solid var(--ion-color-primary);
    }

    --ion-color-secondary: #115950;
    --ion-color-secondary-rgb: 17,89,80;
    --ion-color-secondary-contrast: #ffffff;
    --ion-color-secondary-contrast-rgb: 255,255,255;
    --ion-color-secondary-shade: #0f4e46;
    --ion-color-secondary-tint: #296a62;

    --ion-color-primary: #22a596;
    --ion-color-primary-rgb: 34,165,150;
    --ion-color-primary-contrast: #ffffff;
    --ion-color-primary-contrast-rgb: 255,255,255;
    --ion-color-primary-shade: #1e9184;
    --ion-color-primary-tint: #38aea1;

    --ion-color-tertiary: #1f86b2;
    --ion-color-tertiary-rgb: 31,134,178;
    --ion-color-tertiary-contrast: #ffffff;
    --ion-color-tertiary-contrast-rgb: 255,255,255;
    --ion-color-tertiary-shade: #1b769d;
    --ion-color-tertiary-tint: #3592ba;

    --ion-color-success: #27eb00;
    --ion-color-success-rgb: 39,235,0;
    --ion-color-success-contrast: #000000;
    --ion-color-success-contrast-rgb: 0,0,0;
    --ion-color-success-shade: #22cf00;
    --ion-color-success-tint: #3ded1a;

    --ion-color-warning: #dee109;
    --ion-color-warning-rgb: 222,225,9;
    --ion-color-warning-contrast: #000000;
    --ion-color-warning-contrast-rgb: 0,0,0;
    --ion-color-warning-shade: #c3c608;
    --ion-color-warning-tint: #e1e422;

    --ion-color-danger: #eb445a;
    --ion-color-danger-rgb: 235,68,90;
    --ion-color-danger-contrast: #ffffff;
    --ion-color-danger-contrast-rgb: 255,255,255;
    --ion-color-danger-shade: #cf3c4f;
    --ion-color-danger-tint: #ed576b;

    --ion-color-dark: #e9f1f2;
    --ion-color-dark-rgb: 233,241,242;
    --ion-color-dark-contrast: #000000;
    --ion-color-dark-contrast-rgb: 0,0,0;
    --ion-color-dark-shade: #cdd4d5;
    --ion-color-dark-tint: #ebf2f3;


    --ion-color-medium: #989aa2;
    --ion-color-medium-rgb: 152,154,162;
    --ion-color-medium-contrast: #000000;
    --ion-color-medium-contrast-rgb: 0,0,0;
    --ion-color-medium-shade: #86888f;
    --ion-color-medium-tint: #a2a4ab;

    --ion-color-light: #222428;
    --ion-color-light-rgb: 34,36,40;
    --ion-color-light-contrast: #ffffff;
    --ion-color-light-contrast-rgb: 255,255,255;
    --ion-color-light-shade: #1e2023;
    --ion-color-light-tint: #383a3e;
  }

  /*
   * iOS Dark Theme
   * -------------------------------------------
   */

  .ios body {
    --ion-background-color: #000000;
    --ion-background-color-rgb: 0,0,0;

    --ion-text-color: #ffffff;
    --ion-text-color-rgb: 255,255,255;

    --ion-color-step-50: #0d0d0d;
    --ion-color-step-100: #1a1a1a;
    --ion-color-step-150: #262626;
    --ion-color-step-200: #333333;
    --ion-color-step-250: #404040;
    --ion-color-step-300: #4d4d4d;
    --ion-color-step-350: #595959;
    --ion-color-step-400: #666666;
    --ion-color-step-450: #737373;
    --ion-color-step-500: #808080;
    --ion-color-step-550: #8c8c8c;
    --ion-color-step-600: #999999;
    --ion-color-step-650: #a6a6a6;
    --ion-color-step-700: #b3b3b3;
    --ion-color-step-750: #bfbfbf;
    --ion-color-step-800: #cccccc;
    --ion-color-step-850: #d9d9d9;
    --ion-color-step-900: #e6e6e6;
    --ion-color-step-950: #f2f2f2;

    --ion-item-background: #000000;

    --ion-card-background: #1c1c1d;
  }

  .ios ion-modal {
    --ion-background-color: var(--ion-color-step-100);
    --ion-toolbar-background: var(--ion-color-step-150);
    --ion-toolbar-border-color: var(--ion-color-step-250);
  }


  /*
   * Material Design Dark Theme
   * -------------------------------------------
   */

  .md body {
    --ion-background-color: #121212;
    --ion-background-color-rgb: 18,18,18;

    --ion-text-color: #ffffff;
    --ion-text-color-rgb: 255,255,255;

    --ion-border-color: #222222;

    --ion-color-step-50: #1e1e1e;
    --ion-color-step-100: #2a2a2a;
    --ion-color-step-150: #363636;
    --ion-color-step-200: #414141;
    --ion-color-step-250: #4d4d4d;
    --ion-color-step-300: #595959;
    --ion-color-step-350: #656565;
    --ion-color-step-400: #717171;
    --ion-color-step-450: #7d7d7d;
    --ion-color-step-500: #898989;
    --ion-color-step-550: #949494;
    --ion-color-step-600: #a0a0a0;
    --ion-color-step-650: #acacac;
    --ion-color-step-700: #b8b8b8;
    --ion-color-step-750: #c4c4c4;
    --ion-color-step-800: #d0d0d0;
    --ion-color-step-850: #dbdbdb;
    --ion-color-step-900: #e7e7e7;
    --ion-color-step-950: #f3f3f3;

    --ion-item-background: #1e1e1e;

    --ion-toolbar-background: #1f1f1f;

    --ion-tab-bar-background: #1f1f1f;

    --ion-card-background: #1e1e1e;
  }
}
